import React, { useEffect } from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

import { Link, graphql } from "gatsby"

import Image from "gatsby-image"

import BackgroundImage from "gatsby-background-image"
import PrimaryButton from "../components/buttons/primary_button"

import "react-typed/dist/animatedCursor.css"
import ServiceIconBoxes from "../components/ServiceIconBoxes"
import { wordSlide } from "../components/animation/word-slide/word_slide"

import Testimonial from "../components/Testimonial"
import { Section2IconList } from "../components/Section2IconList"

import truncate from "../utils/truncateString"

import IndustryWeServe from "../components/IndustryWeServe"

const IndexPage = ({ data }) => {
  useEffect(() => {
    wordSlide()
  }, [])

  const posts = data.allMarkdownRemark.edges
  const testimonials = data.testimonials.nodes

  const whychooseus_items = [
    {
      title: "Customer first",
      description:
        "Explorelogy puts our customers first. We prioritise your needs and work within your timelines to get the job done the way you want. ",
    },
    {
      title: "Industry expertise ",
      description:
        "Our team consists of industry experts and professionals with experience in providing IT solutions to a range of businesses working in diverse industries. ",
    },
    {
      title: "Easy experience",
      description:
        "We understand that getting into technicalities can be exhausting. We are here to simplify the tech-stuff and provide you with an easy experience. ",
    },
    {
      title: "Diverse possibilities",
      description:
        "We are experienced in a spectrum of technologies, software and applications and are experts at providing diverse solutions that are innovative and efficient.",
    },
    {
      title: "Customised solutions",
      description:
        "Every IT solution we provide will cater to your business requirements and engage in approaches that best remedy the challenges you face.",
    },
    {
      title: "A long run partner",
      description:
        "We don’t stop at interim results. We enable long term solutions and can provide you with continued assistance till your company is ready to take over.",
    },
  ]

  const bgImage = data.bgImage.childImageSharp.fluid
  const ipadImage = data.ipad.childImageSharp.fluid
  const exploreImage = data.exploreImage.childImageSharp.fluid
  const innovateImage = data.innovateImage.childImageSharp.fluid
  const accelerateImage = data.accelerateImage.childImageSharp.fluid
  const section3BgImage = data.section3BgImage.childImageSharp.fluid

  const getConsult = {
    background: data.getConsult.childImageSharp.fluid,
    global: data.globalConsult.childImageSharp.fluid,
  }

  return (
    <Layout>
      <SEO
        title="Explorelogy | Explore Innovate Accelerate"
        description=""
        metaImage={bgImage}
      />
      <Section1 ipadImage={ipadImage} bgImage={bgImage} />
      <Section2
        exploreImage={exploreImage}
        innovateImage={innovateImage}
        accelerateImage={accelerateImage}
      />

      <Section3
        clientImages={data.clientImages}
        backgroundImage={section3BgImage}
        testomonial={testimonials}
        whychooseus_items={whychooseus_items}
        getConsult={getConsult}
      />
      <div className=" pt-3 pb-5 mb-5">
        <BlogSection data={posts} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    ...IndustryWeServeFragment
    bgImage: file(relativePath: { eq: "home/index_2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ipad: file(relativePath: { eq: "home/ipad.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    exploreImage: file(relativePath: { eq: "home/explore_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teamImage: file(relativePath: { eq: "home/section-2-team.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    innovateImage: file(relativePath: { eq: "home/innovate_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accelerateImage: file(relativePath: { eq: "home/accelerate_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section3BgImage: file(
      relativePath: { eq: "home/section-3-team-with-phone.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920, grayscale: false) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testomonial1: file(relativePath: { eq: "testomonial/2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testomonial2: file(relativePath: { eq: "testomonial/1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    getConsult: file(relativePath: { eq: "home/get_started.jpg" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    globalConsult: file(relativePath: { eq: "home/global.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
      }
    }
    testimonials: allTestimonialsJson {
      nodes {
        description
        className
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        name
        role
      }
    }
  }
`

const BlogSection = ({ data }) => {
  return (
    <div>
      <div className="col-md-6 mx-auto my-5 pt-5 pb-3">
        <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontWeight: 100,
            letterSpacing: 4,
            textTransform: "uppercase",
            fontSize: 10,
          }}
        >
          from our blog
        </h6>

        <div style={{ height: 6 }}></div>
        <h4
          className="m-0"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "normal",
            fontWeight: "100",
            fontSize: "2.25rem",
            lineHeight: "1.2em",
          }}
        >
          Living in the digital age. Stay updated with the latest trends and key
          stories
        </h4>
        <div style={{ height: 10 }}></div>
      </div>
      <div className="container">
        <div className="row">
          {data &&
            data.map((item, index) => {
              return (
                <PostItem
                  key={item.node.id}
                  title={item.node.frontmatter.title}
                  description={item.node.frontmatter.description}
                  to={item.node.fields.slug}
                  date={item.node.frontmatter.date}
                  image={
                    item.node.frontmatter.featuredimage &&
                    item.node.frontmatter.featuredimage.childImageSharp.fluid
                  }
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

const PostItem = ({ title, description, image, to, date }) => {
  return (
    <div className="col-md-3 mb-3 mb-md-0">
      <div className="post-home">
        <div className="post-home-inner">
          <Link to={`${to}`}>
            <div className="post-home-image">
              <Image fluid={image} alt="explorelogy feature image" />
            </div>
            <div className="post-home-wrap">
              <span className="post-home-date">{date}</span>
              <h4 className="post-home-title">{title}</h4>
              <div className="post-home-content">
                {truncate(description, 200, true)}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

// section 01

const Section1 = ({ bgImage, ipadImage }) => {
  return (
    <>
      <section className="section1 container-fluid">
        <BackgroundImage
          Tag="div"
          fadeIn
          fluid={bgImage}
          className="row custom-section-1 mx-0"
        >
          <div className="col-sm-2 pl-0 pr-0"></div>
          <div className="col-sm-8 pl-0 pr-0">
            <div className="column-inner">
              <h4 className="say-good-bye">A one time IT solution to</h4>
              <div>
                <h4 className="word-slide cd-headline push">
                  <span style={{ fontWeight: 300, marginRight: 15 }}>stay</span>
                  <span className="cd-words-wrapper" style={{ width: 470 }}>
                    <b className="is-visible">upgraded</b>
                    <b className="is-hidden">protected</b>
                    <b className="is-hidden">connected</b>
                  </span>
                </h4>
                {/* <h1 className="cd-heading">
                  stay{" "}
                  <span id="typing-heading">
                    <Typed
                      strings={["upgraded", "connected", "protected"]}
                      typeSpeed={100}
                      backDelay={1000}
                      backSpeed={100}
                      loop
                    ></Typed>
                  </span>

                </h1> */}
              </div>
            </div>
            <div className="separator pb-3">
              <span className="sep_holder ">
                <span className="sep_line"></span>
              </span>
            </div>
            <div className="col-10 mx-auto home-subtitle">
              Partner with Explorelogy, the IT services company of the future
            </div>
            <div className="d-none d-md-block" style={{ height: 40 }}></div>
          </div>
          <div className="col-sm-2 pl-0 pr-0"></div>
        </BackgroundImage>
        <div className="row custom-section-2 mx-0">
          <div className="col-11 col-md-11 mx-auto">
            <div className="column-inner">
              <div style={{ width: "100%" }} className="separator">
                <span className="sep_holder d-none d-md-block">
                  <span className="sep_line"></span>
                </span>
                <p className="seporator-p">
                  Diversified solutions to suit your requirements
                </p>
                <span className="sep_holder d-none d-md-block">
                  <span className="sep_line"></span>
                </span>
              </div>
              <div style={{ height: 40 }}></div>
              {/* service icon boxes */}
              <ServiceIconBoxes />
              {/* service icon boxes */}

              <div className="text-column">
                <p style={{ textAlign: "center" }}>
                  Facing an IT glitch that needs a reliable fix? &nbsp;{" "}
                  <Link className="link" to="/services/">
                    Find your solution
                  </Link>
                </p>
              </div>
              <div style={{ height: 230 }}></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row ">
          <div className="col-md-6 mx-auto">
            <div className="bottom-image text-center position-relative">
              <div>
                <Image className="img-fluid" fluid={ipadImage}></Image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// section 02

const Section2 = ({ exploreImage, innovateImage, accelerateImage }) => {
  return (
    <section className="section2 container-fluid">
      <div className="col-md-6 mx-auto my-5 pt-5 pb-3">
        <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontWeight: 100,
            letterSpacing: 4,
            textTransform: "uppercase",
            fontSize: 10,
          }}
        >
          we are explorelogy
        </h6>

        <div style={{ height: 6 }}></div>
        <h4 className="m-0 section-heading-2">
          What makes Explorelogy the recommended IT solutions company for you?
        </h4>
        <div style={{ height: 10 }}></div>
        {/* <p className="m-0" style={{ textAlign: "center" }}>
          Today we’re proud to boast a strong team of IT engineers who thrive on
          rolling up their sleeves and solving your IT problems and meeting your
          business needs.
        </p> */}
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-9 mb-5">
            <div className="row">
              <div className="col-md-7">
                <BackgroundImage
                  Tag="div"
                  fluid={exploreImage}
                  className="section-2-team"
                ></BackgroundImage>
              </div>
              <div className="col-md-5">
                <div className="container-box light-blue">
                  <h3
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "100",
                      marginTop: 20,
                      fontSize: "2.25rem",
                      lineHeight: "1.2em",
                    }}
                  >
                    Explore
                  </h3>
                  <p className="dark-font" style={{ fontSize: 15 }}>
                    We are curious, alert, and ever learning. Get industry
                    expertise and insight into
                  </p>
                  <div style={{ height: 10 }}></div>
                  <ul className="section-2-iconlist">
                    <Section2IconList dark>
                      The field of technology
                    </Section2IconList>
                    <Section2IconList dark>
                      Ever-changing business needs
                    </Section2IconList>
                    <Section2IconList dark>
                      Potential risks and safety threats
                    </Section2IconList>
                    <Section2IconList dark>
                      Global best practices
                    </Section2IconList>
                  </ul>
                  <div style={{ height: 20 }}></div>
                  <PrimaryButton
                    to="/services"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "90%",
                    }}
                    title="Find Out More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-9 mb-5">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-md-5">
                <div className="container-box light-brown">
                  <h3
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "100",
                      marginTop: 20,
                      fontSize: "2.25rem",
                      lineHeight: "1.2em",
                    }}
                  >
                    Innovate
                  </h3>
                  <p style={{ fontSize: 15 }}>
                    We are creative, inventive and transformational. Experience
                    innovative breakthroughs with
                  </p>
                  <div style={{ height: 10 }}></div>
                  <ul className="section-2-iconlist">
                    <Section2IconList>
                      Novel approaches to IT challenges
                    </Section2IconList>
                    <Section2IconList>
                      Creative and personalised solutions
                    </Section2IconList>
                    <Section2IconList>
                      Diverse and evolving product options
                    </Section2IconList>
                  </ul>
                  <div style={{ height: 20 }}></div>
                  <PrimaryButton
                    to="/services"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "90%",
                    }}
                    title="Find Out More"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <BackgroundImage
                  Tag="div"
                  fluid={innovateImage}
                  className="section-2-team"
                ></BackgroundImage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-9 mb-5">
            <div className="row">
              <div className="col-md-7">
                <BackgroundImage
                  Tag="div"
                  fluid={accelerateImage}
                  className="section-2-team"
                ></BackgroundImage>
              </div>
              <div className="col-md-5">
                <div className="container-box sky-blue">
                  <h3
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "100",
                      marginTop: 20,
                      fontSize: "2.25rem",
                      lineHeight: "1.2em",
                    }}
                  >
                    Accelerate
                  </h3>
                  <p className="dark-font" style={{ fontSize: 15 }}>
                    We are futuristic and forward thinking. Results that will
                    get you ahead with
                  </p>
                  <div style={{ height: 10 }}></div>
                  <ul className="section-2-iconlist">
                    <Section2IconList dark>
                      Cutting edge IT solutions
                    </Section2IconList>
                    <Section2IconList dark>
                      Efficient and speedy processes
                    </Section2IconList>
                    <Section2IconList dark>
                      Holistic and reliable safety measures
                    </Section2IconList>
                  </ul>
                  <div style={{ height: 20 }}></div>
                  <PrimaryButton
                    to="/services"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "90%",
                    }}
                    title="Find Out More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mx-auto pt-5 pb-5">
        {/* <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontWeight: 100,
            letterSpacing: 4,
            textTransform: "uppercase",
            fontSize: 10,
          }}
        >
          service delivery KPIS
        </h6>

        <div style={{ height: 6 }}></div>
        <h2
          className="m-0"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "normal",
            fontWeight: "100",
          }}
        >
          HOW WE PERFORMED IN MARCH 2019
        </h2> */}
        {/* <div style={{ height: 100 }}></div> */}
      </div>
    </section>
  )
}

const Section3 = ({
  backgroundImage,
  testomonial,
  whychooseus_items,
  getConsult,
  clientImages,
}) => {
  return (
    <>
      <IndustryWeServe
        clientImages={clientImages}
        backgroundImage={backgroundImage}
      />

      {/* //TODO---------------------------------------------  ADD CLIENT LOGO SLIDER ----------------------------------------------------------------------- */}
      <div className="col-md-6 mx-auto pt-5 mt-5">
        <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontWeight: 100,
            letterSpacing: 4,
            textTransform: "uppercase",
            fontSize: 10,
          }}
        >
          why choose us
        </h6>
        <div style={{ height: 6 }}></div>
        <h4
          className="m-0"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "normal",
            fontWeight: "100",
            fontSize: "2.25rem",
            lineHeight: "1.2em",
          }}
        >
          6 reasons Explorelogy is the solution your company needs
        </h4>
        <div style={{ height: 20 }}></div>
      </div>
      <div>
        <div style={{ marginTop: 0 }} className="container-fluid why-choose-us">
          <div className="container">
            <div className="row">
              {whychooseus_items.map((item, index) => (
                <div key={index} className="col-md-4 py-4 px-4">
                  <div className="whychoose-us-item">
                    <div>
                      <div>
                        <span className="number">{index + 1}</span>
                      </div>
                      <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                        {item.title}
                      </h4>
                      <p style={{ fontWeight: 100 }}>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 80 }}></div>
      <BackgroundImage
        Tag="div"
        fluid={getConsult.background}
        className="get-consult"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5 mx-auto">
              <BackgroundImage
                fluid={getConsult.global}
                className="consult-box"
              >
                <div className="text-center">
                  <h4 style={{ textTransform: "uppercase", fontSize: 25 }}>
                    Comprehensive IT solutions customised for you
                  </h4>
                  <div style={{ height: 30 }}></div>
                  <h4 className="years" style={{ textTransform: "uppercase" }}>
                    it's
                  </h4>
                  <div style={{ height: 10 }}></div>
                  <h5
                    style={{
                      margin: 0,
                      textAlign: "center",
                      color: "#191937",
                      textTransform: "uppercase",
                    }}
                  >
                    the future
                  </h5>
                  <div style={{ height: 70 }}></div>

                  <PrimaryButton
                    style={{ width: "100%" }}
                    to="/contact/"
                    title="GET STARTED"
                  />
                </div>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <Testimonial testomonial={testomonial} />
      <h1 hidden>Explorelogy</h1>
      <h1 hidden>Software</h1>
      <h1 hidden>IT services</h1>
    </>
  )
}

export default IndexPage
