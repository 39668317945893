function truncate (str, n, useWordBoundary) {
  if (str.length <= n) {
    return str
  }
  const subString = str.substr(0, n - 1) // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  )
}

String.prototype.truncate =
  String.prototype.truncate ||
  function (n, useWordBoundary) {
    if (this.length <= n) {
      return this
    }
    const subString = this.substr(0, n - 1) // the original check
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString) + '...'
    )
  }

export default truncate
