import React from "react"
import OwlCarousel from "react-owl-carousel2"
import LightButton from "./buttons/light_button"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const IndustryWeServe = ({ backgroundImage, clientImages }) => {
  console.log(clientImages)
  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundImage}
      className="section3 container-fluid"
    >
      <div className="col-md-6 mx-auto my-5 pt-5 pb-3">
        <h6
          className="m-0"
          style={{
            textAlign: "center",
            fontWeight: 100,
            letterSpacing: 4,
            textTransform: "uppercase",
            fontSize: 10,
            color: "#fff",
          }}
        >
          industries we serve
        </h6>

        <div style={{ height: 6 }}></div>
        <h4
          className="m-0"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "normal",
            fontWeight: "100",
            color: "#fff",
            fontSize: "2.25rem",
            lineHeight: "1.2em",
          }}
        >
          We work together with our business partners
        </h4>
        <div style={{ height: 10 }}></div>
        <p
          style={{
            fontSize: "1rem",
            fontWeight: 100,
            textAlign: "center",
            color: "#fff",
          }}
        >
          Our standards and expertise knowledge allows you to uplift your
          business from existing level to brand new level and streamline your
          business operation which increases your productivity. No matter from
          which industry you are, we will cover your requirements with our
          solutions without any doubt.
        </p>
        <div style={{ height: 10 }}></div>
        <div className="text-center">
          <LightButton to="/products" title="View Our Products" />
        </div>
      </div>
      <div className="container">
        <OwlCarousel
          options={{
            items: 5,
            loop: true,
            nav: false,
            autoplay: true,
            autoplayTimeout: 2000,
            responsive: {
              0: {
                items: 2,
                nav: false,
                rewind: true,
                autoplay: true,
              },
              766: {
                items: 5,
                nav: false,
                rewind: true,
                autoplay: true,
              },
              1200: {
                items: 5,
                nav: false,
                rewind: true,
                autoplay: true,
              },
            },
          }}
        >
          {clientImages.nodes.map((e, index) => {
            const image = getImage(e)
            return (
              <div key={index} className="client-logo">
                <div className="client-logo-slider">
                  <GatsbyImage image={image} alt="" />
                </div>
              </div>
            )
          })}
        </OwlCarousel>
      </div>
    </BackgroundImage>
  )
}

export const query = graphql`
  fragment IndustryWeServeFragment on Query {
    clientImages: allFile(filter: { relativeDirectory: { eq: "clients" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
`

export default IndustryWeServe
