import $ from 'jquery'

export function wordSlide () {
  $(document).ready(function ($) {
    const animationDelay = 2500
    const barAnimationDelay = 3800
    const barWaiting = barAnimationDelay - 3000
    const lettersDelay = 50
    const typeLettersDelay = 150
    const selectionDuration = 500
    const typeAnimationDelay = selectionDuration + 800
    const revealDuration = 600
    const revealAnimationDelay = 1500

    initHeadline()
    function initHeadline () {
      singleLetters($('.cd-headline.letters').find('b'))
      animateHeadline($('.cd-headline'))
    }

    function singleLetters ($words) {
      let i
      $words.each(function () {
        const word = $(this)
        const letters = word.text().split('')
        const selected = word.hasClass('is-visible')
        for (i in letters) {
          if (word.parents('.rotate-2').length > 0) { letters[i] = '<em>' + letters[i] + '</em>' }
          letters[i] = selected
            ? '<i class="in">' + letters[i] + '</i>'
            : '<i>' + letters[i] + '</i>'
        }
        const newLetters = letters.join('')
        word.html(newLetters).css('opacity', 1)
      })
    }
    function animateHeadline ($headlines) {
      let duration = animationDelay
      $headlines.each(function () {
        const headline = $(this)
        if (headline.hasClass('loading-bar')) {
          duration = barAnimationDelay
          setTimeout(function () {
            headline.find('.cd-words-wrapper').addClass('is-loading')
          }, barWaiting)
        } else if (headline.hasClass('clip')) {
          const spanWrapper = headline.find('.cd-words-wrapper')
          const newWidth = spanWrapper.width() + 10
          spanWrapper.css('width', newWidth)
        } else if (!headline.hasClass('type')) {
          const words = headline.find('.cd-words-wrapper b')
          let width = 0
          words.each(function () {
            const wordWidth = $(this).width()
            if (wordWidth > width) width = wordWidth
          })
          headline.find('.cd-words-wrapper').css('width', width)
        }
        setTimeout(function () {
          hideWord(headline.find('.is-visible').eq(0))
        }, duration)
      })
    }

    function hideWord ($word) {
      const nextWord = takeNext($word)
      if ($word.parents('.cd-headline').hasClass('type')) {
        const parentSpan = $word.parent('.cd-words-wrapper')
        parentSpan.addClass('selected').removeClass('waiting')
        setTimeout(function () {
          parentSpan.removeClass('selected')
          $word
            .removeClass('is-visible')
            .addClass('is-hidden')
            .children('i')
            .removeClass('in')
            .addClass('out')
        }, selectionDuration)
        setTimeout(function () {
          showWord(nextWord, typeLettersDelay)
        }, typeAnimationDelay)
      } else if ($word.parents('.cd-headline').hasClass('letters')) {
        const bool =
              $word.children('i').length >= nextWord.children('i').length
                ? !0
                : !1
        hideLetter($word.find('i').eq(0), $word, bool, lettersDelay)
        showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay)
      } else if ($word.parents('.cd-headline').hasClass('clip')) {
        $word
          .parents('.cd-words-wrapper')
          .animate({ width: '2px' }, revealDuration, function () {
            switchWord($word, nextWord)
            showWord(nextWord)
          })
      } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
        $word.parents('.cd-words-wrapper').removeClass('is-loading')
        switchWord($word, nextWord)
        setTimeout(function () {
          hideWord(nextWord)
        }, barAnimationDelay)
        setTimeout(function () {
          $word.parents('.cd-words-wrapper').addClass('is-loading')
        }, barWaiting)
      } else {
        switchWord($word, nextWord)
        setTimeout(function () {
          hideWord(nextWord)
        }, animationDelay)
      }
    }

    function showWord ($word, $duration) {
      if ($word.parents('.cd-headline').hasClass('type')) {
        showLetter($word.find('i').eq(0), $word, !1, $duration)
        $word.addClass('is-visible').removeClass('is-hidden')
      } else if ($word.parents('.cd-headline').hasClass('clip')) {
        $word
          .parents('.cd-words-wrapper')
          .animate(
            { width: $word.width() + 10 },
            revealDuration,
            function () {
              setTimeout(function () {
                hideWord($word)
              }, revealAnimationDelay)
            }
          )
      }
    }
    function hideLetter ($letter, $word, $bool, $duration) {
      $letter.removeClass('in').addClass('out')
      if (!$letter.is(':last-child')) {
        setTimeout(function () {
          hideLetter($letter.next(), $word, $bool, $duration)
        }, $duration)
      } else if ($bool) {
        setTimeout(function () {
          hideWord(takeNext($word))
        }, animationDelay)
      }
      if (
        $letter.is(':last-child') &&
            $('html').hasClass('no-csstransitions')
      ) {
        const nextWord = takeNext($word)
        switchWord($word, nextWord)
      }
    }
    function showLetter ($letter, $word, $bool, $duration) {
      $letter.addClass('in').removeClass('out')
      if (!$letter.is(':last-child')) {
        setTimeout(function () {
          showLetter($letter.next(), $word, $bool, $duration)
        }, $duration)
      } else {
        if ($word.parents('.cd-headline').hasClass('type')) {
          setTimeout(function () {
            $word.parents('.cd-words-wrapper').addClass('waiting')
          }, 200)
        }
        if (!$bool) {
          setTimeout(function () {
            hideWord($word)
          }, animationDelay)
        }
      }
    }
    function takeNext ($word) {
      return !$word.is(':last-child')
        ? $word.next()
        : $word.parent().children().eq(0)
    }
    // function takePrev($word) {
    //   return !$word.is(":first-child")
    //     ? $word.prev()
    //     : $word.parent().children().last()
    // }
    function switchWord ($oldWord, $newWord) {
      $oldWord.removeClass('is-visible').addClass('is-hidden')
      $newWord.removeClass('is-hidden').addClass('is-visible')
    }
  })
}
