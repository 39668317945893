import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const SingleTestimonialItem = ({ comment, author, role, image }) => {
  return (
    <div className="single-testimonial-item col-md-12">
      <div className="testimonial-content" style={{ marginRight: 20 }}>
        <p>{comment}</p>
      </div>
      <div className="testimonial-author">
        {/* <img
          src="https://cssmaterial.com/apetech/demo/img/testimonial-4.png"
          alt=""
        /> */}

        <BackgroundImage
          style={{
            width: 50,
            height: 50,
            borderRadius: 100,
            overflow: 'hidden',
            marginRight: 15,
            marginLeft: 10
          }}
          Tag={'div'}
          fluid={image}
        ></BackgroundImage>

        <div className="testimonial-author-info">
          <h6>{author}</h6>
          <span>{role}</span>
        </div>
      </div>
    </div>
  )
}

export default SingleTestimonialItem
