import React, { useRef } from 'react'
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/lib/styles.css'
import SingleTestimonialItem from './SingleTestomonialItem'

const Testimonial = ({ testomonial }) => {
  const testomonialRef = useRef('testomonialRef')
  const testomonialOptions = {
    options: {
      items: 2,
      nav: true,
      navText: [
        '<span><i class="fas fa-chevron-left"></i></span>',
        '<span><i class="fas fa-chevron-right"></i></span>'
      ],
      rewind: true,
      autoplay: true,
      // loop: true,

      responsive: {
        0: {
          items: 1,
          nav: true,
          rewind: true,
          autoplay: true
        },
        766: {
          items: 1,
          nav: true,
          rewind: true,
          autoplay: true
        },
        1200: {
          items: 2,
          nav: true,
          rewind: true,
          autoplay: true
        }
      }
    }
  }
  return (
    <div className="testomonial-two-columns col-11 col-md-8 d-block d-md-flex p-0 mx-auto">
      <OwlCarousel ref={testomonialRef} options={testomonialOptions.options}>
        {testomonial.map((testominal, index) => (
          <SingleTestimonialItem
            key={index}
            comment={testominal.description}
            role={testominal.role}
            author={testominal.name}
            image={testominal.image.childImageSharp.fluid}
          />
        ))}
      </OwlCarousel>
    </div>
  )
}

export default Testimonial
